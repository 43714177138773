import axios from "axios";
import { Config } from "../config";
import { CampaignProps } from "@/interfaces/campaign";




export const GetAdvertCampaign = async (token: string) => {
  try {
    const data = await axios({
      method: "get",
      maxBodyLength: Infinity,
      url: Config.apiEndpoint + `campaign/get-advert`,
      headers: {
        "accept-language": "th",
        "x-access-token": token,
        Authorization: "Basic " + Config.encoded,
        "Content-Type": "application/json",
      },
    });
    return data.data;
  } catch (error) {
    // if (axios.isAxiosError(error) && error.response) {
    //   error = error.response;
    //   return error;
    // }
  }
};



export const GetCampaignDetail = async (token: string, slug: any) => {
  try {
    const data = await axios({
      method: "get",
      maxBodyLength: Infinity,
      url: Config.apiEndpoint + `campaign/get-campaign-detail/${slug}`,
      headers: {
        "accept-language": "th",
        "x-access-token": token,
        Authorization: "Basic " + Config.encoded,
        "Content-Type": "application/json",
      },
    });
    return data as any;
  } catch (error) {
    // if (axios.isAxiosError(error) && error.response) {
    //   error = error.response;
    //   return error;
    // }
  }
};

export const GetCampaign = async (token: string) => {
  try {
    const data = await axios({
      method: "get",
      maxBodyLength: Infinity,
      url: Config.apiEndpoint + `campaign/get-campaign`,
      headers: {
        "accept-language": "th",
        "x-access-token": token,
        Authorization: "Basic " + Config.encoded,
        "Content-Type": "application/json",
      },
    });
    return data as any;
  } catch (error) {
   
  }
};

export const GetCampaignProduct = async (
  token: string,
  campaign_id: any,
  limit: any = 100,
  order: any = ""
) => {
  try {
    const data = await axios({
      method: "get",
      maxBodyLength: Infinity,
      url:
        Config.apiEndpoint +
        `campaign/get-campaign-product/${campaign_id}?limit=${limit}&order=${order}`,
      headers: {
        "accept-language": "th",
        "x-access-token": token,
        Authorization: "Basic " + Config.encoded,
        "Content-Type": "application/json",
      },
    });
    return data as any;
  } catch (error) {
    // if (axios.isAxiosError(error) && error.response) {
    //   error = error.response;
    //   return error;
    // }
  }
};

export const PostFriends = async (
  token: string,
  dataCampaignGetFriend: CampaignProps
) => {
  try {
    const data = await axios({
      method: "post",
      maxBodyLength: Infinity,
      url: Config.apiEndpoint + `campaign/post-friend-get-friend`,
      headers: {
        "accept-language": "th",
        "x-access-token": token,
        Authorization: "Basic " + Config.encoded,
        "Content-Type": "application/json",
      },
      data: dataCampaignGetFriend,
    });
    return data as any;
  } catch (error) {
    // if (axios.isAxiosError(error) && error.response) {
    //   error = error.response;
    //   return error;
    // }
  }
};

// export const getCurrentCampaign = async () => {
//   try {
//     const { data } = await axios({
//       method: "get",
//       maxBodyLength: Infinity,
//       url: Config.apiCampaignEndpoint + `/campaign/get-campaign`,
//       headers: {
//         "accept-language": "th",
//         Authorization: "Basic " + Config.encoded,
//         "Content-Type": "application/json",
//       },
//     });

//     return data as any;
//   } catch (error) {
//     throw error;
//   }
// };

export const getCurrentCampaign = async () => {
  try {
    const { data } = await axios({
      method: 'get',
      maxBodyLength: Infinity,
      url: `${Config.apiCampaignEndpoint}/campaign/get-campaign`,
      headers: {
        'accept-language': 'th',
        Authorization: `Basic ${Config.encoded}`,
        'Content-Type': 'application/json',
      },
    });

    return data;
  } catch (error) {
    console.error('Error fetching current campaign:', error); 
   
  }
};
