import Cookies from "universal-cookie";

interface IStatus {
	isLoggedIn: boolean
}

export const checkLoginStatus = (): IStatus => {
  const cookies =  new Cookies();
  const token = cookies.get("token");

	if(token) {
		return {
			isLoggedIn: true,
		}

	} else {
		return{
			isLoggedIn: false
		}
	}
}
