import axios from 'axios';
import { Config, ConfigMember } from '../config';


// let data = JSON.stringify({
//   deviceType: "website",
//   username: "",
//   firstName: "",
//   lastName: "",
//   birthdate: "1992-08-31",
//   gender: "m",
//   email: "",
//   profileImage: ""
// });

// console.log(data) 

export const GetProfile = async (token: string) => {
  try {
    const data = await axios({
      method: 'post',
      maxBodyLength: Infinity,
      url: ConfigMember.apiEndpoint + `v1/member/profile-member`,
      headers: {
        'accept-language': 'th', 
        "Authorization": "Basic " + Config.encoded,
        "x-access-token": token,
        "Content-Type": "application/json",
      }
    })

    // console.log("11111");
    
    return data as any;
  }
  catch(error) {
    // if (axios.isAxiosError(error) && error.response) {
    //   error = error.response;
    //   return error;
    // }
  };
}

export const EditDataProfile = async (token: string) => {
  try {
    const data = await axios({
      method: 'post',
      maxBodyLength: Infinity,
      url: ConfigMember.apiEndpoint + `v1/member/edit-profile-member`,
      headers: {
        'accept-language': 'th', 
        "Authorization": "Basic " + Config.encoded,
        "x-access-token":token,
        "Content-Type": "application/json",
      }
    })
    return data as any;
  }
  catch(error) {
    // if (axios.isAxiosError(error) && error.response) {
    //   error = error.response;
    //   return error;
    // }
  };
}


export const GetDataProfile = async (token: string) => {
  try {
    const data = await axios({
      method: 'post',
      maxBodyLength: Infinity,
      url: ConfigMember.apiEndpoint + `v1/member/profile-member`,
      headers: {
        'accept-language': 'th', 
        "Authorization": "Basic " + Config.encoded,
        "x-access-token":token,
        "Content-Type": "application/json",
      }
    })
    // console.log("11111");
    
    return data as any;
  }
  catch(error) {
    // if (axios.isAxiosError(error) && error.response) {
    //   error = error.response;
    //   return error;
    // }
  };
}



