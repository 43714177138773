import Cookies from 'universal-cookie';
import Grid from '@mui/material/Unstable_Grid2';
import Image from 'next/image';
import Link from 'next/link';
import Paper from '@mui/material/Paper';
import React, { useEffect, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import styled from 'styled-components';
import {
  Autoplay,
  FreeMode,
  Navigation,
  Pagination,
  Thumbs
  } from 'swiper';
import {
  Backdrop,
  Box,
  Button,
  CardMedia,
  Fade,
  Modal,
  Stack,
  Typography,
  useMediaQuery
  } from '@mui/material';
import { checkLoginStatus } from '@/utils/authen';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GetAdvertCampaign } from '@/api/campaign';
import { GetCheckPinMember } from '@/api/member';
import { GetProfile } from '@/api/getProfile';
import { ImgUrl } from '@/config';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useProfileContext } from '@/hooks/profile-context';
import { useRouter } from 'next/router';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/thumbs';


// Import Swiper styles
// import { setCookie, getCookie } from '../cookie';

type Props = {
  dataBannerHome: string[];
};
const CustomSwiper = styled(Swiper)`
  .swiper-button-prev,
  .swiper-button-next {
    width: 40px;
    height: 40px;
    background-size: 20px 20px;
  }
`;

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  // width: 400,
  bgcolor: 'none',
  border: 0,
  boxShadow: 0,
  p: 4,
  outline: 0
};


const styleLoginPin = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'none',
  border: 0,
  boxShadow: 0,
  p: 4,
  outline: 0
};

export default function Bannerhome({ Bannerhome }: any) {

  const { dataProfile, setDataProfile } = useProfileContext();
  const cookie = new Cookies();
  const token = cookie.get('token');


  const { isLoggedIn } = checkLoginStatus();
  const router = useRouter();
  const banner = "/assets/images/banner/banner-home.png";
  const [thumbsSwiper, setThumbsSwiper] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [Getadvert, setGetadvert] = useState<any>([]);
  const [GetLoginpin, setGetOpenLoginpin] = useState([]);
  const [openPin, setOpenPin] = React.useState(false);
  // const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [GetCheckPin, setGetCheckPin] = useState({});
  const memberId = cookie.get("memberId") as number;
  // console.log(memberId);
  const isMobile = useMediaQuery("(max-width:600px)");
  const [swiperHeight, setSwiperHeight] = useState('auto');






  const handleClose = () => {
    cookie.set('hasSeenCampaignModal', 'true');
    cookie.set('campaignModalID', 0);
    setOpen(false);
  };

  const handleCloseLoginPin = () => {
    cookie.set('hasSeenLoginPinModal', 'true');
    cookie.set('PinModalID', 0);
    setOpenPin(false);
  };

  if (Bannerhome === null) {
    return "";
  }
  if (typeof Bannerhome === "object" && Bannerhome !== null && "message" in Bannerhome) {
    return Bannerhome.message;
  }


  const GetCampaign = async (token: string) => {
    const res = await GetAdvertCampaign(token);

    if (res?.success == true) {
      setGetadvert(res.data);
    }

  };

  const FetchGetCheckPinMember = async (token: string, memberId: number) => {
    // const memberIdAsNumber = memberId;

    const res = await GetCheckPinMember(token, memberId);

    // console.log(typeof memberId);
    if (isLoggedIn) {
      if (res?.status === true) {
        if (res.data === null) {
          setOpenPin(true);
        } else {
          setOpenPin(false);
          // router.push(`/profile`);
        }
      }
    }
  };




  const fetchDataProfile = async (token: string) => {
    const response = await GetProfile(token);
    if (response?.status === true) {
      const expirationDate = new Date();
      expirationDate.setDate(expirationDate.getDate() + 3);
      cookie.set('img_profile', response.data.profileImageFullUrl, { path: "/", expires: expirationDate });
      cookie.set('username', response.data.username, { path: "/", expires: expirationDate });
      cookie.set('memberId', response.data.id, { path: "/", expires: expirationDate });
      cookie.set('pinNew', response.data.pin, { path: "/", expires: expirationDate });
      setDataProfile(response.data);
    }
  };

  useEffect(() => {
    const hasSeenCampaignModal = cookie.get('hasSeenCampaignModal');
    const campaignModalID = cookie.get('campaignModalID');
    if (!hasSeenCampaignModal && !open && campaignModalID !== 0) {
      setOpen(true);
    }
    // GetCampaign(token);


    const hasSeenLoginPinModal = cookie.get('hasSeenLoginPinModal');


    if (!hasSeenLoginPinModal && isLoggedIn) {
      FetchGetCheckPinMember(token, memberId);
    }

    if (token) {
      fetchDataProfile(token);
    }
  }, [token],);

  const handleToCampaign = (link: any, utm: any, target: any) => {

    const url = ReactHtmlParser(link + utm) as any;

    window.open(url, `${target}`);

  }

  // const swiperHeight = isMobile ? 'auto' : '450px';



  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;



      if (screenWidth >= 1920) {
        setSwiperHeight('auto');
      } else if (screenWidth >= 2000) {
        setSwiperHeight('auto');
      } else if (screenWidth == 1440) {
        setSwiperHeight('auto');
      }
    };




    handleResize();


    window.addEventListener('resize', handleResize);


    return () => {
      window.removeEventListener('resize', handleResize);
    };

  }, []);



  return (
    <>

      <Box>
        <Modal
          aria-labelledby="spring-modal-title"
          aria-describedby="spring-modal-description"
          open={openPin}
          onClose={handleCloseLoginPin}
          closeAfterTransition
        >

          <Fade in={openPin}>
            <Box>

              <Box sx={styleLoginPin}>
                <Box sx={{ textAlign: "end" }}>
                  <FontAwesomeIcon
                    style={{ 
                      width: '16px', cursor: 'pointer', fontSize: "15px",
                      padding: '5px', borderRadius: '50%',
                      backgroundColor: '#FFFFFF'
                    }}
                    icon={faClose}
                    onClick={handleCloseLoginPin}
                  />
                </Box>
                <Link href={"/profile/loginpin"}>
                <Image 
                    src={`${ImgUrl.pathImg}web/set-pin.png`}
                    width={300}
                    height={300}
                    alt="" 
                    loading="lazy"
                    placeholder="blur" 
                    blurDataURL={`${ImgUrl.pathImg}web/set-pin-blur.png`}
                  />
                </Link>
              </Box>
            </Box>
          </Fade>
        </Modal>
      </Box>



      <Box sx={{ flexGrow: 1 }} >


        <CustomSwiper
          style={{
            "--swiper-navigation-color": "#000000",
            "--swiper-pagination-color": "#fff",
            overflow: "hidden",
            height: swiperHeight,
            borderRadius: "14px",

          }}
          spaceBetween={10}
          // navigation={true}
          navigation={{
            prevEl: ".custom-swiper-button-prev",
            nextEl: ".custom-swiper-button-next",
          }}


          thumbs={{
            swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null,
          }}
          pagination={{
            dynamicBullets: true,
          }}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          speed={1000}
          modules={[FreeMode, Navigation, Thumbs, Pagination, Autoplay]}
          slidesPerView={1}
          loop={true}
        >
          {Bannerhome?.map((item: any, index: number) => (

            <SwiperSlide key={item.sort}>
              {isMobile ? (
                <Link href={ReactHtmlParser(item.link) + item.track_utm} target={item.target}
                  className="banner-detail-mobile">

                  <CardMedia
                    component="img"
                    sx={{
                      width: "100%",
                      height: "auto",
                      maxHeight: "150px",
                      objectFit: "cover",
                    }}
                    image={item.image_mobile_url ? item.image_mobile_url : banner}
                    alt={item.image_mobile_url ? item.image_mobile_url : banner}
                  />

                </Link>
              ) : (
                <Box sx={{ position: "relative", width: "100%", mt: 0, height: "auto" }}>
                  <Image
                    onClick={() => handleToCampaign(item.link, item.track_utm, item.target)}
                    src={item.image_url ? item.image_url : banner}
                    alt={"banner-img"}
                    layout="responsive"
                    objectFit="contain"
                    height={500}
                    width={1}
                    // priority
                    style={{ maxWidth: "100%", height: "auto", borderRadius: "14px" }}
                    loading="lazy"
                    placeholder="blur"
                    blurDataURL={item.image_url || "/assets/images/banner/banner-home.png"}
                  />
                </Box>

              )}
            </SwiperSlide>
          ))}
        </CustomSwiper>

      </Box>
    </>

  );
}
